import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Layout, PricingCard, Bullet } from 'components';

const StyledPricing = styled.div`
  background: var(--light-blue);
  h1 {
    color: var(--black);
    margin-bottom: 50px;
  }
`;

const StyledPricingInfo = styled.div`
  padding-left: 100px;
  h3 {
    font-size: 36px;
    line-height: 2.5rem;
  }
  ul {
    padding-left: 20px;
    li {
      margin-top: 1rem;
    }
  }
  hr {
    margin: 2rem 0;
  }
`;

const PricingPage = ({
  data: {
    prismicPricingPage: {
      data: {
        title,
        rate,
        rate_text1,
        rate_text2,
        heading,
        content,
        pricing_includes,
        pricing_add_ons,
        pricing_add_ons_2,
      },
    },
  },
}) => {
  return (
    <Layout>
      <StyledPricing>
        <div className="uk-container uk-container-medium uk-padding-large">
          <h1>{title.text}</h1>
          <div className="uk-child-width-1-2@m" uk-grid="true">
            <PricingCard
              rate={rate}
              rate_text1={rate_text1}
              rate_text2={rate_text2}
              heading={heading}
              content={content}
            />
            <StyledPricingInfo className="uk-padding-large">
              <div className="uk-grid-medium" uk-grid="true">
                <Bullet color={'blue'} />
                <div className="uk-width-expand uk-padding-remove-left">
                  <h3>This includes:</h3>
                </div>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: pricing_includes.html }}
              />
              <hr />
              <div className="uk-grid-medium" uk-grid="true">
                <Bullet color={'blue'} />
                <div className="uk-width-expand uk-padding-remove-left">
                  <h3>Add-Ons:</h3>
                </div>
              </div>
              <ul>
                {pricing_add_ons.raw.map((li) => (
                  <li>{li.text}</li>
                ))}
                <ul>
                  {pricing_add_ons_2.raw.map((li) => (
                    <li>{li.text}</li>
                  ))}
                </ul>
              </ul>
            </StyledPricingInfo>
          </div>
        </div>
      </StyledPricing>
    </Layout>
  );
};

export default PricingPage;

export const query = graphql`
  query {
    prismicPricingPage {
      data {
        title {
          text
        }
        rate
        rate_text1
        rate_text2
        heading {
          text
        }
        content {
          html
        }
        pricing_includes {
          html
        }
        pricing_add_ons {
          raw
        }
        pricing_add_ons_2 {
          raw
        }
      }
    }
  }
`;
